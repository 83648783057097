import './page.style.css';

import { Fragment } from 'react';

export default function GuidePage() {
  return (
    <Fragment>
      To-do: write guide
    </Fragment>
  );
}
